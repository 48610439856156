import { useState } from "react";
import { Appli } from "../object/Appli";
import Cookies from "universal-cookie";

export const useApp = () => {
  const getApp = () => {
    const appString = (new Cookies()).get('JRA_app') ?? "";
    
    let app:Appli[] = [{ uid: "", name: "", url: "", iconUrl: "", isJraApp: false }];
    if(appString !== "" && typeof appString === "string" && appString !== "undefined")
      app = JSON.parse(appString)
    else if (appString !== "" && appString !== "undefined")
      app = appString
    return app
  };
  const [ app, setApp ] = useState<Appli[]>(getApp());
  
  const saveApp = (app:Appli[]) => {
    (new Cookies()).set('JRA_app', JSON.stringify(app))
    setApp(app);
  };
  return { app,  setApp:saveApp };
};
import style from './assets/scss/accueil.module.scss';
import styleModal from '../../components/modal/modal.module.scss';
import { FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Cron } from '../../services/object/Cron';
import { Server } from '../../services/object/Server';


interface SalepointPopupChildrensProps {
    errorMessages: Record<string, string>,
    formRefCron: React.RefObject<HTMLFormElement>,
    cron: Cron|undefined;
    TOServer: Server[];
    handleFormCronChange: (property: string, value: string | FileList | null |number | boolean ) => void
  }
    const FormPopup = ({errorMessages, 
                        formRefCron, 
                        cron, 
                        TOServer,
                        handleFormCronChange} :SalepointPopupChildrensProps) => {
                            

  const [localFormValues, setLocalFormValues] = useState({
                                                            uid : "",
                                                            createdAt : "",
                                                            modifiedAt : "",
                                                            executedAt : "",
                                                            name : "",
                                                            cronTime : "",
                                                            function :  "",
                                                            object : "",
                                                            params : "",
                                                            success : "",
                                                            serverUid : "",
                                                            response : ""
                                                        })     
                                                            
  useEffect(() => {
    if (cron) {
        setLocalFormValues({
                uid : cron?.uid ?? "",
                name : cron?.name ?? "",
                cronTime : cron?.cronTime ?? "",
                createdAt : cron?.createdAt ?? "",
                modifiedAt : cron?.modifiedAt ?? "",
                executedAt : cron?.executedAt ?? "",
                function : cron?.function ?? "",
                object : cron?.object ?? "",
                params : cron?.params ?? "",
                success : cron?.success ?? "",
                response : cron?.response ?? "",
                serverUid : cron?.serverUid ?? "",
        });
    }
  }, [cron]) 
  
    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        handleFormCronChange(name, value);
    }
  
    const handleLocalChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let { name, value } = e.target;
        setLocalFormValues((prevValues) => ({
          ...prevValues,
          [name]: value,
        }));
      }

    return (<form className={style.uiform} ref={formRefCron}>
                <h2  className={styleModal.subtitle}>Les informations générales :</h2>
                <div className={style.contentText}>
                    <TextField fullWidth label="Sujet"  
                        size="medium"
                        value={localFormValues.name}
                        name="name"
                        onBlur={handleBlur}
                        onChange={(e) => handleLocalChange(e)} required
                        error={!!errorMessages.name}
                        helperText={errorMessages.name}/>
                    <FormControl>
                        <InputLabel className={style.selectLabel}>Serveur</InputLabel>
                        <Select
                            required
                            name='server'
                            size="medium"
                            input={<OutlinedInput label="Catégorie" />}
                            value={localFormValues.serverUid}
                            onChange={(e) => handleFormCronChange('serverUid', e.target.value)}
                            error={!!errorMessages.server}>
                                {TOServer.map((server : Server) => (
                                    <MenuItem key={server.uid} value={server.uid}>
                                        <ListItemText primary={server.name} />
                                    </MenuItem>
                                ))}
                        </Select>
                        <FormHelperText className='error'>{errorMessages.server}</FormHelperText>
                    </FormControl>
                    <TextField fullWidth label="Objet"  
                        size="medium"
                        value={localFormValues.object}
                        name="object"
                        onBlur={handleBlur}
                        onChange={(e) => handleLocalChange(e)} required
                        error={!!errorMessages.object}
                        helperText={errorMessages.object}/>
                    <TextField fullWidth label="Fonction"  
                        size="medium"
                        value={localFormValues.function}
                        name="function"
                        onBlur={handleBlur}
                        onChange={(e) => handleLocalChange(e)} required
                        error={!!errorMessages.function}
                        helperText={errorMessages.function}/>
                        
                    <TextField fullWidth label="Fréquence d'exécution (♦ heure UTC ♦)"  
                        size="medium"
                        value={localFormValues.cronTime}
                        name="cronTime"
                        onBlur={handleBlur}
                        onChange={(e) => handleLocalChange(e)} required
                        error={!!errorMessages.cronTime}
                        helperText={errorMessages.cronTime}/>                  

                    <TextField fullWidth label="Paramètres (séparés par des &: uid=1&name=nom)"  
                        size="medium"
                        value={localFormValues.params}
                        name="params"
                        onBlur={handleBlur}
                        onChange={(e) => handleLocalChange(e)} 
                        error={!!errorMessages.params}
                        helperText={errorMessages.params}
                        multiline
                        rows={4}/>
                    {
                        (cron?.uid) ? 
                            <TextField fullWidth label="Dernière exécution"  
                                    size="medium"
                                    value={localFormValues.response}
                                    name="response"
                                    disabled={true}
                                    multiline
                                    rows={4}/>
                        :
                            ""
                    }
                </div>
            </form>)
}
export default FormPopup;
import  { useState } from 'react';
import Cookies from 'universal-cookie';

export default function useToken() {
  const getToken = () => {
    let tokenString = (new Cookies()).get('JRA_token') ?? "";
    return tokenString
  };
  const [token, setToken] = useState(getToken());

  const saveToken = (userToken:string) => {
    (new Cookies()).set('JRA_token', JSON.stringify(userToken));
    setToken(userToken);
  };


  return {
    setToken: saveToken,
    token
  }
}
import { useState } from "react";
import { Law } from "../object/Law";
import Cookies from 'universal-cookie';

export const useLaw = () => {
  const getLawString = () => {
    const TLawString = (new Cookies()).get('JRA_law') ?? "";
    let law:string[] = [""];
    if(TLawString !== "")
      law = TLawString
    return law
  };
  const [ TLawString, setLawString ] = useState<string[]>(getLawString());
  const saveLaw = (TLawString:string[]) => {
    (new Cookies()).set('JRA_law', JSON.stringify(TLawString));
    setLawString(TLawString);
  };
  return { TLawString, setLaw:saveLaw };
};
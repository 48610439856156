import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import style from './assets/scss/userbutton.module.scss';
import LienUtile from './LienUtile';
import { Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/Logout';
import Stack from '@mui/material/Stack';
import { User } from '../../services/object/User';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { useAuth } from '../../services/auth/useAuth';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuList from '@mui/material/MenuList';
import { Job } from '../../services/object/Job';
import {Service_Api} from '../../services/Api';
import Cookies from 'universal-cookie';


function stringToColor(string: string) {
  let hash = 0;
  let i;
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  return color;
}

function stringAvatar(name: string) {
  if (name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1] ? name.split(' ')[1][0] : ''}`.toUpperCase(),
    };
  } else {
    return {
      sx: {
        bgcolor: '#000000',
      },
      children: 'X',
    };
  }
}

export default function AccountMenu() {
  const { user, logout } = useAuth();
  const [userUpdate, setUserUpdate] = useState<User>(user);
  const [activeLink, setActiveLink] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [jobs, setJobs] = useState<Job[]>([]);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const Api = Service_Api(); 

  useEffect (() => {
    fetchJob();
  }, [])

  const fetchJob = async () => {
    try {
      const response = await Api.get('job/');
       setJobs(response?.data); 
    } catch (error) {
      console.error('Erreur lors de la récupération des données');
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  const handleStorageChange = () => {
    const updatedUserString = (new Cookies()).get('JRA_user');
    if (updatedUserString) {
        const updatedUser: User = JSON.parse(updatedUserString);
        setUserUpdate(updatedUser);
    }
  };
  window.addEventListener('storage', handleStorageChange);

  const jobName = jobs?.find((job: Job) => job.uid === userUpdate.jobUid)?.name;

  const avatarProps = userUpdate && userUpdate.photo
    ? { src: userUpdate.photo }
    : { ...stringAvatar(`${userUpdate?.firstname ?? ''} ${userUpdate?.lastname ?? ''}`) };

  return (
    <div className={style.dropdownMenu}>

      <Stack direction="row" spacing={2}>
          <div >
            <IconButton
                ref={anchorRef}
                id="composition-button"
                aria-controls={open ? 'composition-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
                
            >
              <Stack direction="row" spacing={2}>
		            <Avatar sx={{ width: 48, height: 48}} {...avatarProps} />
		          </Stack>
            </IconButton>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              placement="bottom-end"
              popperOptions={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [16, 0],
                    },
                  },
                ],
              }}
              transition
              className={style.popperRoot}
            >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                  placement === 'bottom-end' ? 'rigth top' : 'rigth bottom',
                }}
              >
              <Paper sx={{marginTop: '5px'}}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <div className={style.username}>
                      <p>{userUpdate?.firstname ?? ''}</p>
                      <p>{userUpdate?.lastname ?? ''}</p>
                      {jobName && <p>{jobName}</p>}
                    </div>

                    <Divider />
                    
                      <MenuItem component={Link} to="/profile"  className={`${style.menuItem} ${location.pathname === '/profile' ? 'active' : ''}`}>
                        <div className={style.menuLink}>
                          <ListItemIcon>
                            <AccountCircleOutlinedIcon/>
                          </ListItemIcon>
                            Profil
                        </div>
                      </MenuItem   >
                    
                      <MenuItem className={style.menuItem} onClick={()=>(logout(), window.location.href = "https://harmonie.jra.tools/deconnexion-crossOrigin")}>
                        <div className={style.menuLink}>
                          <ListItemIcon>
                            <Logout/>
                          </ListItemIcon>
                            Déconnexion
                        </div>
                      </MenuItem  >
                    
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
      <div className={style.divider}><Divider orientation="vertical" /></div>
      <div className={style.lienUtile}>
        <LienUtile/>
      </div>
    </div>
  );
}
import { useState } from "react";
import useToken from "./useToken";
import { useUser } from "./useUser";
import { User } from "../object/User";
import { Law } from "../object/Law";
import { useLaw } from "./useLaw";

export const useAuth = (token:string = "") => {
  const { setToken } = useToken();
  const [isConnected, setIsConnected]  = useState(true);
  const {user, setUser}  = useUser();
  const {TLawString, setLaw}  = useLaw();

  const logout = () => {
    setToken("");
    setUser({ uid: "", firstname: "", lastname: "", email: "", photo: "", position: "", authToken: "", jobUid : "" });
    setLaw([""]);
    setIsConnected(false)
  };

  const login = (user: User, TLawString : string[]) => {
    setUser(user);
    setLaw(TLawString);
  };

  const updateUser = (updatedUser: User) => {
    setUser(updatedUser);
  };

  return { isConnected , user, setUser, TLawString, setLaw, logout, login, updateUser};
};

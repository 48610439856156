import { useState } from "react";
import { User } from "../object/User";
import Cookies from 'universal-cookie';

export const useUser = () => {
  const getUser = () => {
    const userString = (new Cookies()).get('JRA_user') ?? "";
    let user:User = { uid: "", firstname: "", lastname: "", email: "", photo: "", position: "", authToken: "", jobUid : "" };
    if(userString !== "")
      user = userString
    return user
  };
  const [ user, setUser ] = useState<User>(getUser());
  const saveUser = (user:User) => {
    (new Cookies()).set('JRA_user', JSON.stringify(user));
    setUser(user);
  };
  return { user, setUser:saveUser };
};
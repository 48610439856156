import * as React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import { NavLink } from 'react-router-dom';
import style from './assets/scss/lienUtile.module.scss';
import ExtensionIcon from '@mui/icons-material/Extension';
import ReactDOM from 'react-dom';
import { StepIconProps } from '@mui/material/StepIcon';
import { useApp } from '../../services/auth/useApp';
import { Appli } from '../../services/object/Appli';
import { fetchFavicon } from '../../services/tools/fetchFavicon';


const LienUtile = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { app } = useApp();
    
    React.useEffect(() => {
      app.forEach(async (app: Appli) => {
        app.iconUrl = await fetchFavicon(app.url);
      })
    }, [app]);
    
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);

    React.useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (anchorEl && !anchorEl.contains(event.target as Node)) {
        handleClose();
      }
    };

    window.addEventListener('click', handleOutsideClick);

    return () => {
      window.removeEventListener('click', handleOutsideClick);
    };
  }, [anchorEl]);



  function fetchIcon(faviconUrl: string): React.ReactNode {
    try {
      if (faviconUrl) {
        return <img src={faviconUrl} alt=''  
         onError={(e) => {
          e.currentTarget.style.display = 'none';
          console.error('Error loading favicon:', faviconUrl);
          const parent = e.currentTarget.parentNode as HTMLElement | null;
          if (parent) {
            const noIconElement = document.createElement('div');
            ReactDOM.render(<ExtensionIcon htmlColor="#008EC8"/>, noIconElement);
            parent.appendChild(noIconElement);
          }
        }}
        />;
      } else {
        return <div><ExtensionIcon htmlColor="#008EC8"/></div>;
      }
    } catch (error) {
      console.error('Error fetching favicon:', error);
      return <div>error</div>;
    }
  }
  
  function CustomStepIcon(props: StepIconProps & {icon: React.ReactNode }) {
    const {icon} = props;
    
    return (
      <div className={style.iconUrl}>
        {icon}
      </div>
    );
  }

  return (
    <Box>
      <Popper 
        open={open} 
        anchorEl={anchorEl} 
        transition
        className={style.popperRoot}
      >
        {({ TransitionProps }) => (
          <Slide {...TransitionProps} timeout={350}>
            <Paper className={style.popperContent}>
              <div className={style.iconUtile}>
                <ul>
                  {app.filter(app => app.isJraApp === false).map((app, index) => (
                    <li key={index }>
                      <NavLink target="_blank" to={app.url}>
                      <Button color='info' variant="text" className={style.button}>
                        <CustomStepIcon icon={fetchIcon(app.iconUrl ?? '')} />
                        <p>{app.name}</p>
                        </Button>
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </div>
            </Paper>
          </Slide>
        )}
      </Popper>
      <IconButton size="small" sx={{marginBlock: '0.3rem', marginInline: '0.5rem'}} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
    </Box>
  );
}

export default LienUtile;
